// EditTenant.js
import axios from "axios";
import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

const EditTenant = ({ tenant, onClose, onSave }) => {
  const [tenantData, setTenantData] = useState({
    o_id:tenant.o_id,
    company_name: tenant.company_name,
    car_capacity: tenant.car_capacity,
    bike_capacity: tenant.bike_capacity,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    setTenantData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem("token");
    console.log(tenantData)
    const responce=axios.post("https://housys.skymarkone.in:8001/api/update-office",
        tenantData,
        {
        headers:{
        Authorization:`Bearer ${token}`
    }})
    onSave(tenantData);
    onClose();
  };

  return (
    <Modal show onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Tenant</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="companyName">
            <Form.Label>Tenant Name</Form.Label>
            <Form.Control
              type="text"
              name="company_name"
              value={tenantData.company_name}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group controlId="carCapacity">
            <Form.Label>Car Capacity</Form.Label>
            <Form.Control
              type="number"
              name="car_capacity"
              value={tenantData.car_capacity}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group controlId="bikeCapacity">
            <Form.Label>Bike Capacity</Form.Label>
            <Form.Control
              type="number"
              name="bike_capacity"
              value={tenantData.bike_capacity}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditTenant;
