import { NavLink ,useNavigate} from "react-router-dom";
import React, { useEffect, useState } from "react";

import {
  FaBars,
  FaHome,
  FaUser,
  FaCogs,
  FaIdCard,
  FaBaseballBall,
  FaTicketAlt,
} from "react-icons/fa";
import { BiAnalyse } from "react-icons/bi";
import { IoMdPhonePortrait } from "react-icons/io";
import {
  AiFillBuild,
  AiOutlineLogout,
  AiTwotoneFileExclamation,
} from "react-icons/ai";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";

const SideBar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 430) {
        setIsOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  const routes = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: (
        <FaHome
          className={`icon-dashboard ${!isOpen ? "icon-shift-left" : ""}`}
          style={{ color: "black" }}
        />
      ),
    },
    {
      path: "/admin",
      name: "Admin",
      icon: (
        <BiAnalyse
          className={`icon-dashboard ${!isOpen ? "icon-shift-left" : ""}`}
          style={{ color: "black" }}
        />
      ),
      subRoutes: [
        { path: "/add-admin", name: "Add-Admin" },
        { path: "/manage-admin", name: "Manage-Admin" },
        // { path: "/add-sub-Gate", name: "Add Sub-Gate" },
        // { path: "/manage-sub-Gate", name: "Manage-Sub-Gate" },
        // { path: "/ticket-sectors", name: "Ticket-Sectors" },
        // { path: "/manage-ticket-sectors", name: "Manage Ticket Sectors" },
        // { path: "/Accred-sectors", name: "Accred. Sectors" },
        // { path: "/Manage-Accred-sectors", name: "Manage Accred. Sectors" },
      ],
    },
    {
      path: "/Tag-management",
      name: "Tag Management",
      icon: (
        <FaUser
          className={`icon-dashboard ${!isOpen ? "icon-shift-left" : ""}`}
          style={{ color: "black" }}
        />
      ),
      subRoutes: [
        //{ path: "/create-roll", name: "Create Roll" },
        { path: "/Tenant/add-tag", name: "Add Tag" },
        //{ path: "/manage-roll", name: "Manage Roll" },
        { path: "/Tenant/manage-tag", name: "Manage Tag" },
      ],
    },

    {
      path: "/Tenant-management",
      name: "Tenant Management",
      icon: (
        <FaUser
          className={`icon-dashboard ${!isOpen ? "icon-shift-left" : ""}`}
          style={{ color: "black" }}
        />
      ),
      subRoutes: [
        { path: "/create-tenant", name: "Create Tenant" },
        
        { path: "/manage-tanant", name: "Manage Tenant" },
 
      ],
    },
    // {
    //   path: "/pavilion",
    //   name: "Pavilion",
    //   icon: (
    //     <AiFillBuild
    //       className={`icon-dashboard ${!isOpen ? "icon-shift-left" : ""}`}
    //       style={{ color: "black" }}
    //     />
    //   ),
    //   subRoutes: [
    //     { path: "/add-pavilion", name: "Add-Pavilion" },
    //     { path: "/manage-Pavilion", name: "Manage-Pavilion" },
    //   ],
    // },

    // {
    //   path: "/machines",
    //   name: "Machines",
    //   icon: (
    //     <FaCogs
    //       className={`icon-dashboard ${!isOpen ? "icon-shift-left" : ""}`}
    //       style={{ color: "black" }}
    //     />
    //   ),
    //   subRoutes: [
    //     { path: "/add-machines", name: "Add-machines" },
    //     { path: "/manage-machines", name: "Manage-machines" },
    //     { path: "/add-sub-machines", name: "Add Sub-machines" },
    //     { path: "/manage-sub-machines", name: "Manage-Sub-machines" },
    //   ],
    // },
    // {
    //   path: "/devices",
    //   name: "Devices",
    //   icon: (
    //     <IoMdPhonePortrait
    //       className={`icon-dashboard ${!isOpen ? "icon-shift-left" : ""}`}
    //       style={{ color: "black" }}
    //     />
    //   ),
    //   subRoutes: [
    //     { path: "/add-Devices", name: "Add-Devices" },
    //     { path: "/manage-Devices", name: "Manage-Devices" },
    //     { path: "/add-sub-Devices", name: "Add Sub-Devices" },
    //     { path: "/manage-sub-Devices", name: "Manage-Sub-Devices" },
    //   ],
    // },
    // {
    //   path: "/Accreditation-Card",
    //   name: "Accreditation Card",
    //   icon: (
    //     <FaIdCard
    //       className={`icon-dashboard ${!isOpen ? "icon-shift-left" : ""}`}
    //       style={{ color: "black" }}
    //     />
    //   ),
    //   subRoutes: [
    //     { path: "/add-Accreditation-Card", name: "Add-Accreditation-Card" },
    //     {
    //       path: "/manage-Accreditation-Card",
    //       name: "Manage-Accreditation-Card",
    //     },
    //     { path: "/add-staff", name: "Add Staff" },
    //     { path: "/manage-Staff", name: "Manage Staff" },
    //   ],
    // },
    // {
    //   path: "/matches",
    //   name: "Matches",
    //   icon: (
    //     <FaBaseballBall
    //       className={`icon-dashboard ${!isOpen ? "icon-shift-left" : ""}`}
    //       style={{ color: "black" }}
    //     />
    //   ),
    //   subRoutes: [
    //     { path: "/Register-New-Matches", name: "Register New Matches" },
    //     { path: "/manage-Matches", name: "Manage-Matches" },
    //   ],
    // },
    // {
    //   path: "/Tickets",
    //   name: "Tickets",
    //   icon: (
    //     <FaTicketAlt
    //       className={`icon-dashboard ${!isOpen ? "icon-shift-left" : ""}`}
    //       style={{ color: "black" }}
    //     />
    //   ),
    //   subRoutes: [
    //     { path: "/dump-tickets", name: "Dump Tickets" },
    //     { path: "/Saved-tickets", name: "Saved Tickets" },
    //   ],
    // },
    {
      path: "/reports",
      name: "Report",
      icon: (
        <AiTwotoneFileExclamation
          className={`icon-dashboard ${!isOpen ? "icon-shift-left" : ""}`}
          style={{ color: "black" }}
        />
      ),
    },
    {
      path: "/signout",
      name: "Logout",
      icon: (
        <AiOutlineLogout
          className={`icon-dashboard ${!isOpen ? "icon-shift-left" : ""}`}
          style={{ color: "black" }}
        />
      ),
    },
  ];

  return (
    <>
      <div className="main-container">
        <motion.div
          className={`sidebar ${isOpen ? "sidebar-open" : "sidebar-closed"}`}
        >
          <div className="top_section">
            <AnimatePresence>
              {isOpen && (
                <motion.h1
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="logo"
                >
                  <img
                    style={{ marginLeft: "10px", width: "90%" }}
                    src="logo-bg.png"
                    className="img-thumbnail"
                    alt="Cinque Terre/"
                  ></img>
                </motion.h1>
              )}
            </AnimatePresence>
            <div className="bars">
              <FaBars
                onClick={toggleSidebar}
                style={{ color: "black", cursor: "pointer" }}
              />
            </div>
          </div>
          <section
            // style={{ fontSize: "23px" }}
            style={{ paddingLeft: "20px", fontSize: "23px" }}
            className="routes"
          >
            {routes.map((route, index) => {
              if (route.subRoutes) {
                return (
                  <SidebarMenu
                    setIsOpen={setIsOpen}
                    route={route}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                    key={index}
                  />
                );
              }
              return (
                <NavLink
                  to={route.path}
                  key={index}
                  className="link"
                  activeClassName="active"
                >
                  <div className="icon-text-padding">
                    <div className="icon">{route.icon}</div>
                    <AnimatePresence>
                      {isOpen && (
                        <motion.div
                          variants={showAnimation}
                          initial="hidden"
                          animate="show"
                          exit="hidden"
                          className="link_text"
                          style={{ color: "black", fontSize: "17px" }} // Apply black color to link text
                        >
                          {route.name}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </NavLink>
              );
            })}
          </section>
        </motion.div>
        <main>{children}</main>
      </div>
    </>
  );
};

export default SideBar;
