import React, { useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import axios from "axios";

const EditEmployee = ({ employee, onClose, onSave }) => {
  const [updatedEmployee, setUpdatedEmployee] = useState(employee);
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedEmployee((prevEmployee) => ({
      ...prevEmployee,
      [name]: value,
    }));
  };

  // Fetch roles on component mount
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found, please log in.");
          return;
        }

        const response = await axios.get("https://housys.skymarkone.in:8001/api/getuserbyrole", {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.data.status === "success") {
          const assignableRoles = response.data.data[0]?.assignableRoles || [];
          setRoles(assignableRoles);
        } else {
          console.error("Error fetching roles:", response.data.msg);
        }
      } catch (error) {
        console.error("Error fetching roles:", error.response?.data || error.message);
      }
    };

    fetchRoles();
  }, []);

  // Handle save changes
  const handleSubmit = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        "https://housys.skymarkone.in:8001/api/update-user",
        updatedEmployee,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.status == "success") {
        onSave(response.data.data);
        onClose();
      } else {
        console.error("Failed to update employee:", response.data.msg);
      }
    } catch (error) {
      console.error("Error updating employee:", error.response?.data || error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Tenant</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formEmployeeName">
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={updatedEmployee.name}
              onChange={handleInputChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formEmployeeMobile" className="mt-3">
            <Form.Label>Mobile Number</Form.Label>
            <Form.Control
              type="text"
              name="mobile_no"
              value={updatedEmployee.mobile_no}
              onChange={handleInputChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formEmployeeRole" className="mt-3">
            <Form.Label>Role</Form.Label>
            <Form.Control
              as="select"
              name="role_id"
              value={updatedEmployee.role_id}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Role</option>
              {roles.map((role) => (
                <option key={role.role_id} value={role.role_id}>
                  {role.role_name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <div className="d-flex justify-content-end mt-4">
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? "Saving..." : "Save Changes"}
            </Button>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditEmployee;
