import React, { useState, useEffect } from "react";
import axios from "axios";
import EditEmployee from './EditEmployee';
import {
  Container,
  Row,
  Col,
  Table,
  Breadcrumb,
  Button,
  Form,
  Card,
} from "react-bootstrap";
import * as XLSX from 'xlsx'; // Make sure to install xlsx package

const ManageEmployee = () => {
  const [offices, setOffices] = useState([]);
  const [selectedOffice, setSelectedOffice] = useState("");
  const [users, setUsers] = useState([]);
  const [editingEmp, setEditingEmp] = useState(null);
  const [employee, setEmployee] = useState([]);
  const [error, setError] = useState("");

  // Handle edit button click
  const handleEditClick = (user) => {
    setEditingEmp(user);
  };

  // Handle delete button click
  const handleDelete = async (userId) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found, please log in.");
        return;
      }

      const response = await axios.get(
        `https://housys.skymarkone.in:8001/api/delete-user/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.status === "success") {
        alert("User deleted successfully.");
        setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
      } else {
        alert(response.data.message || "Failed to delete user.");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      alert("An error occurred while deleting the user.");
    }
  };

  // Function to get the office name based on selectedOffice ID
  const getOfficeName = (officeId) => {
    const office = offices.find((office) => office.office_id === Number(officeId));
    if (office) {
      return office.office_name;
    } else {
      return "Unknown Office";
    }
  };

  const handleSave = (updatedEmployee) => {
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === updatedEmployee.id ? updatedEmployee : user
      )
    );
  };

  // Fetch offices
  useEffect(() => {
    const fetchOffices = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found, please log in.");
          return;
        }

        const response = await axios.get("https://housys.skymarkone.in:8001/api/getoffice", {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.data?.office) {
          setOffices(response.data.office || []);
        } else {
          console.error("Error fetching offices:", response.data.msg);
        }
      } catch (error) {
        console.error("Error fetching offices:", error.response?.data || error.message);
      }
    };

    fetchOffices();
  }, []);

  // Fetch users by selected office
  useEffect(() => {
    const fetchUsersByOffice = async () => {
      if (!selectedOffice) return;

      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found, please log in.");
          return;
        }

        const response = await axios.get(
          `https://housys.skymarkone.in:8001/api/user/getUserSpecificOffice/${selectedOffice}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (Array.isArray(response.data.data)) {
          setUsers(response.data.data);
        } else {
          console.error("Data format error:", response.data);
        }
      } catch (error) {
        console.error("Error fetching users:", error.response?.data || error.message);
      }
    };

    fetchUsersByOffice();
  }, [selectedOffice]);

  // Handle Excel download
  const handleDownloadExcel = () => {
    if (users.length === 0) {
      alert("No data available to export.");
      return;
    }

    const ws = XLSX.utils.json_to_sheet(users); // Convert users data to a sheet
    const wb = XLSX.utils.book_new(); // Create a new workbook
    XLSX.utils.book_append_sheet(wb, ws, "Users"); // Append sheet to workbook
    XLSX.writeFile(wb, "users.xlsx"); // Download the workbook
  };

  return (
    <Container fluid className="p-4" style={{ minHeight: "100vh" }}>
      <Row className="mb-3">
        <Col xs={12} lg={8} className="mb-2 mb-lg-0">
          <h2>Registered User Details</h2>
          <p>All registered User Details</p>
        </Col>
        <Col xs={12} lg={4} className="d-flex justify-content-lg-end align-items-center">
          <Button
            variant="primary"
            onClick={handleDownloadExcel}
            disabled={users.length === 0} // Disable button if no data
          >
            Excel <i className="bi bi-download"></i>
          </Button>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Breadcrumb>
            <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active>Live Logs</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row className="flex-grow-1">
        <Col xs={12} lg={8} className="mb-2 mb-lg-0">
          <Form.Group controlId="formOfficeSelect" className="mb-4">
            <Form.Label>
              Select Tenant<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              as="select"
              value={selectedOffice}
              onChange={(e) => setSelectedOffice(e.target.value)}
            >
              <option value="">Select Tenant</option>
              {offices.map((office) => (
                <option key={office.office_id} value={office.office_id}>
                  {office.office_name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>

        <Col xs={12}>
          <Card className="mt-3 flex-grow-1">
            <Card.Header className="bg-white">
              <strong>TOP 100 RECORDS</strong>
            </Card.Header>
            <Card.Body className="d-flex flex-column">
              <div style={{ overflowX: "auto" }}>
                <Table striped hover responsive className="mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Tenant Name</th>
                      <th>Role Name</th>
                      <th>Name</th>
                      <th>Username</th>
                      <th>Mobile Number</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user, index) => (
                      <tr key={user.id}>
                        <td>{index + 1}</td>
                        <td>{getOfficeName(selectedOffice)}</td>
                        <td>{user.role_id}</td>
                        <td>{user.name}</td>
                        <td>{user.username}</td>
                        <td>{user.mobile_no}</td>
                        <td>
                          <Button
                            variant="link"
                            size="sm"
                            className="text-primary p-0 me-2"
                            onClick={() => handleEditClick(user)}
                          >
                            <i className="bi bi-pencil"></i>
                          </Button>
                          <Button
                            variant="link"
                            size="sm"
                            className="text-danger p-0"
                            onClick={() => handleDelete(user.id)}
                          >
                            <i className="bi bi-trash"></i>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {editingEmp && (
        <EditEmployee
          employee={editingEmp}
          onClose={() => setEditingEmp(null)}
          onSave={handleSave}
        />
      )}
    </Container>
  );
};

export default ManageEmployee;
