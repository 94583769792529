import React, { useState, useEffect } from "react";
import axios from 'axios';

const LiveActivityLogs = () => {
  const [logs, setLogs] = useState([]);
 
  const office_id = localStorage.getItem("office_id");
  const token = localStorage.getItem("token")

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://housys.skymarkone.in:8001/api/getlast10-entry-exits/${office_id}`,
          {
            headers: { Authorization: `Bearer ${token}`, },
          }
        );
        setLogs(response.data.data);
      } 
      catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div
      style={{
        minHeight: "570px",
        borderRadius: "10px",
        overflowX: "auto",
      }}
      className="p-3 border bg-white text-dark mt-3"
    >
      <h4 className="mb-3">Live Activity Logs</h4>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th style={{ width: "15%", textAlign: "center" }}>Vehicle Number</th>
              <th style={{ width: "15%", textAlign: "center" }}>Card Number</th>
              <th style={{ width: "15%", textAlign: "center" }}>Vehicle Type</th>
              <th style={{ width: "20%", textAlign: "center" }}>Entry DateTime</th>
              <th style={{ width: "20%", textAlign: "center" }}>Exit DateTime</th>
            </tr>
          </thead>
          <tbody>
            {logs.length > 0 ? (
              logs.map((log, index) => (
                <tr key={index}>
                  <td style={{ textAlign: "center" }}>
                    {log.Vehicle ? log.Vehicle.vehicle_number : "N/A"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {log.Vehicle ? log.Vehicle.card_no : "N/A"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {log.Vehicle ? log.Vehicle.vehicle_type : "N/A"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {log.entry_datetime || "N/A"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {log.exits_timedate || "N/A"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center">
                  No logs available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LiveActivityLogs;
