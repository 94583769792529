import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";

const AssignPermission = () => {
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found, please log in.");
          return;
        }

        const response = await axios.get("https://housys.skymarkone.in:8001/api/getuserbyrole", {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.data.status === "success") {
          const assignableRoles = response.data.data[0]?.assignableRoles || [];
          setRoles(assignableRoles);
        } else {
          console.error("Error fetching roles:", response.data.msg);
        }
      } catch (error) {
        console.error("Error fetching roles:", error.response?.data || error.message);
      }
    };

    const fetchPermissions = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("https://housys.skymarkone.in:8001/api/getallpermission", {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.data.status === "success") {
          setPermissions(response.data.allpermission);
        } else {
          console.error("Error fetching permissions:", response.data.msg);
        }
      } catch (error) {
        console.error("Error fetching permissions:", error.response?.data || error.message);
      }
    };

    fetchRoles();
    fetchPermissions();
  }, []);

  const handleCheckboxChange = (permissionId) => {
    setSelectedPermissions((prevSelected) =>
      prevSelected.includes(permissionId)
        ? prevSelected.filter((id) => id !== permissionId)
        : [...prevSelected, permissionId]
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
alert(selectedRole)
    const newAdminData = {
      roleId: selectedRole,
      permissionIds: selectedPermissions,
    };

    try {
      const response = await axios.post(
        "https://housys.skymarkone.in:8001/api/assign-permissions",
        newAdminData,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (response.data.status === "success") {
        alert("Assign Roll Permission successfully.");
      } else {
        console.error("Failed to Assign Permission", response.data);
        alert("Failed to Assign Permission."+ response.data);
      }
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
      alert("Failed to Assign Permission."+error.response?.data || error.message);
    }
  };

  return (
    <Container fluid className="mt-4">
      <h2 className="mb-4">Assign Permission</h2>
      <div className="card p-4" style={{ minHeight: "98vh" }}>
        <Form onSubmit={handleSubmit} style={{ width: "90%", marginLeft: "55px" }}>
          <Row className="mt-4">
            <Col xs={6}>
              <Form.Group controlId="roleSelect">
                <Form.Label>Select Role<span style={{ color: "red" }}>*</span></Form.Label>
                <Form.Control
                  as="select"
                  value={selectedRole}
                  onChange={(e) => setSelectedRole(e.target.value)}
                >
                  <option value="">Select Role</option>
                  {roles.map((role) => (
                    <option key={role.role_id} value={role.role_id}>
                      {role.role_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col xs={6}>
  <Form.Group controlId="formPermissions">
    <Form.Label>Select Permissions</Form.Label>
    <div className="d-flex flex-wrap">
      {permissions.map((permission) => (
        <Form.Check
          inline
          type="checkbox"
          key={permission.p_id}
          label={permission.permission_name}
          value={permission.p_id}
          checked={selectedPermissions.includes(permission.p_id)}
          onChange={() => handleCheckboxChange(permission.p_id)}
          className="me-3" // Adds spacing between checkboxes
        />
      ))}
    </div>
  </Form.Group>
</Col>

          </Row>

          <Row className="mt-3">
            <Col xs={12} className="d-flex justify-content-start">
              <Button variant="primary" type="submit" className="me-4">
                Submit
              </Button>
              <Button variant="secondary" type="button">
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Container>
  );
};

export default AssignPermission;
