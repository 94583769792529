import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import axios from "axios";

const Signout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const signout = async () => {
      try {
        const response = await axios.post(
          "https://housys.skymarkone.in:8001/api/user/signout",
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.status === 200) {
          // Clear the token and reload the window
          localStorage.removeItem("token");
          localStorage.removeItem("role_name");
          navigate("/"); // Navigate to login page
          window.location.reload(); // Reload the page
        }
      } catch (error) {
        console.error("Error signing out:", error);
      }
    };

    signout();
  }, [navigate]);

  return <div></div>;
};

export default Signout;
